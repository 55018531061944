import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import { addLang } from "./langHelper";
import microfrontendLayout from "./microfrontend-layout.html";
import "./styles.css";

addLang();
const data = {
  loaders: {},
  props: {
    typeOfUser: "INVESTOR",
    baseAppRoute: "/",
    blockchainNetwork: "ethereumNetwork",
  },
};
const routes = constructRoutes(microfrontendLayout, data);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
