export const LANG_REGEX: RegExp = /\/([\w]{2})\//g;

const DEFAULT_LANG = "en";

export const urlLang = () => {
  const pathName = location.pathname;
  return pathName.replace(LANG_REGEX, "$1") || DEFAULT_LANG;
};

export const hasLanguage = () => LANG_REGEX.test(location.pathname);
export const addLang = () => {
  const hasToAddTheLanguage = !hasLanguage();
  if (hasToAddTheLanguage) {
    const pathName = location.pathname;
    window.history.replaceState(null, "", `${DEFAULT_LANG}${pathName}`);
  }
};
